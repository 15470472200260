import cookie from 'cookiejs';

export default function () {
  const popup = document.getElementById('popup-message');

  if (popup) {
    const { id, dismissTime } = popup.dataset;
    const cookieName = `popup-${id}`;
    if (!cookie.get(cookieName)) {
      const modal = window.modalManager.idModalMap.get('popup-message');
      modal.on('before-hide', () => {
        cookie.set(cookieName, '1', parseInt(dismissTime)); //eslint-disable-line
      });
      setTimeout(() => {
        modal.show();
      }, 3000);
    }
  }
}
