import $ from 'jquery';

export default function () {
  const $specialisation = $('.js-specialisation');
  const $detail = $('.js-specialisation-detail');
  const $sidebars = $('.sidebar');
  const $sidebarSpecialisation = $('.sidebar.js-sidebar-specialisation');
  const $sidebarSpecialisationDetail = $('.sidebar.js-sidebar-specialisation-detail');


  $specialisation.click(() => {
    if ($(window).width() < 1024) {
      const $otherSidebars = $.grep($sidebars, a => a !== $sidebarSpecialisation[0]);
      if ($otherSidebars.length === 0) {
        $sidebarSpecialisation.slideToggle('slow');
      } else {
        $($otherSidebars).slideUp('slow', () => {
          $sidebarSpecialisation.slideToggle('slow');
        });
      }
    } else {
      return true;
    }
    return false;
  });

  $detail.click(() => {
    if ($(window).width() < 1024) {
      const $otherSidebars = $.grep($sidebars, a => a !== $sidebarSpecialisationDetail[0]);
      if ($otherSidebars.length === 0) {
        $sidebarSpecialisationDetail.slideToggle('slow');
      } else {
        $($otherSidebars).slideUp('slow', () => {
          $sidebarSpecialisationDetail.slideToggle('slow');
        });
      }
    } else {
      return true;
    }
    return false;
  });

  // eslint-disable-next-line no-restricted-globals
  // eslint-disable-next-line func-names
  $(window).bind('resize', function () {
    /* false to get page from cache */
    if ($(window).width() > 1024) {
      this.location.reload(false);
    }
    return false;
  });
}
