import $ from 'jquery';
// import StickySidebar from 'sticky-sidebar';

export default function () {
  // const $nav = $('.main-nav__wrap');

  // $('.main-nav__trigger').click((e) => {
  //   e.preventDefault();
  //   $(e.currentTarget).next('.main-nav__wrap').slideToggle('fast');
  // });

  // if ($('.sidebar').length) {
  //   const sticky = new StickySidebar('.sidebar', {
  //     topSpacing: 150,
  //     bottomSpacing: 0,
  //     innerWrapperSelector: '.sidebar__inner',
  //     resizeSensor: false,
  //   });
  //   $(window).on('resize', () => {
  //     sticky.updateSticky();
  //   });
  // }
  const $mobileNav = $('.mobile-nav');

  function openMobile(width) {
    $($mobileNav).animate({
      right: '0px',
    }, 200);
    $('body').animate({
      left: width,
    }, 200);
    $('.bg-overlay').show();
    $('.mobile-nav--trigger').hide();
    $('.mobile-nav--close').show();
  }

  function closeMobile(width) {
    $($mobileNav).animate({
      right: width,
    }, 200);
    $('body').animate({
      left: '0px',
    }, 200);
    $('.bg-overlay').hide();
    $('.mobile-nav--close').hide();
    $('.mobile-nav--trigger').show();
  }

  $('.mobile-nav--trigger').click((e) => {
    e.preventDefault();
    if ($(window).width() < 768) {
      openMobile('-100vw');
    } else {
      openMobile('-360px');
    }
  });
  $('.mobile-nav--close').click((e) => {
    e.preventDefault();
    if ($(window).width() < 768) {
      closeMobile('-100vw');
    } else {
      closeMobile('-360px');
    }
  });
  $('.bg-overlay').click((e) => {
    e.preventDefault();
    if ($(window).width() < 768) {
      closeMobile('-100vw');
    } else {
      closeMobile('-360px');
    }
  });
}
