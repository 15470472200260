import RecaptchaForm from '../components/forms/RecaptchaForm';

export default class Reservation {
  constructor() {
    // Recaptcha submit handler for each form
    const reservationForm = new RecaptchaForm('#form-reservation');
    const doctorSelect = document.querySelector('#doctor');
    const specializationSelect = document.querySelector('#specialization');

    window.submitRecaptchaFormReservation = () => {
      reservationForm.submitCallback();
    };

    doctorSelect.addEventListener('change', () => {
      specializationSelect.value = '';
    });

    specializationSelect.addEventListener('change', () => {
      doctorSelect.value = '';
    });
  }
}
