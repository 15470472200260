
import $ from 'jquery';

export default function () {
  function getNameAttr(index, $slides) {
    const $name = $slides.eq((index)).find('.specialist__item').data('name');
    return $name;
  }
  function setNameAttr(currentSlide) {
    const $slides = $('.slick-slide');
    const $prevArrow = $('.slick-prev .value');
    const $nextArrow = $('.slick-next .value');

    if (currentSlide === 0) {
      $prevArrow.html(getNameAttr(($slides.length - 1), $slides));
      $nextArrow.html(getNameAttr((currentSlide + 1), $slides));
    } else if (currentSlide === ($slides.length - 1)) {
      $prevArrow.html(getNameAttr((currentSlide - 1), $slides));
      $nextArrow.html(getNameAttr(0, $slides));
    } else {
      $prevArrow.html(getNameAttr((currentSlide - 1), $slides));
      $nextArrow.html(getNameAttr((currentSlide + 1), $slides));
    }
  }
  // eslint-disable-next-line no-unused-vars
  $('.specialist-slider').on('afterChange', (event, slick, currentSlide) => {
    setNameAttr(currentSlide);
  });

  // eslint-disable-next-line no-unused-vars
  $('.specialist-slider').on('init', (slick) => {
    setNameAttr(0);
  });

  $('.specialist-slider').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: '<div class="slick-next slick-arrow"><div class="value"></div><i class="icon icon-arrow-right"></i></div>',
    prevArrow: '<div class="slick-prev slick-arrow"><i class="icon icon-arrow-left"></i><div class="value"></div></div>',
  });
}
