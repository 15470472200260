export default class Doctors {
  constructor() {
    const filters = [...document.querySelectorAll('.filter input')];
    filters.forEach((trigger) => {
      trigger.addEventListener('change', () => {
        this.updateActiveDoctors();
      });
    });
  }

  updateActiveDoctors() {
    const activeFilters = [...document.querySelectorAll('.filter input:checked')];
    if (activeFilters.length) {
      [...document.querySelectorAll('.doctor__item-wrap')].forEach((doctor) => {
        doctor.classList.add('doctor__item--hide');
      });
      const activeFilterValues = [];
      activeFilters.forEach((activeFilter) => {
        const filterLowercase = activeFilter.getAttribute('value').toLowerCase();
        activeFilterValues.push(filterLowercase);
      });

      activeFilterValues.forEach((activeFilterClass) => {
        const activeLowercase = activeFilterClass.toLowerCase();
        [...document.querySelectorAll(`.js-tag-${activeLowercase}`)].forEach((dov) => {
          dov.classList.remove('doctor__item--hide');
        });
      });
    } else {
      [...document.querySelectorAll('.doctor__item-wrap')].forEach((doctor) => {
        doctor.classList.remove('doctor__item--hide');
      });
    }
  }
}
